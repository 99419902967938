import React from "react";
import { connect } from "react-redux";
import { numberFormat } from "../../App/Currency";
import { useNavigate } from "react-router-dom";
import { clearPage } from "../../../actions/ItemAddToCart";
import { useTranslation } from "react-i18next";

export const mapStateToProps = (state) => ({
  ...state,
  ...state.ItemAddToCart,
  //   ...state.CategorySliderBar,
});

export const mapDispatchToProps = (dispatch) => ({
  clearPage: () => dispatch(clearPage()),
});

const PayAppIndex = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let settings = JSON.parse(localStorage.getItem("app_settings"));
  let order_details_pay = JSON.parse(localStorage.getItem("order_details_pay"));
  const [coverImageCss, setCoverImageCss] = React.useState({
    background: "",
  });

  React.useEffect(() => {
    setCoverImageCss({
      background: "url(" + settings.kioski_cover_image + ") no-repeat",
    });
    // document.documentElement.style.setProperty(
    //   "--theme-color-1",
    //   settings.restaurant_color
    // );
  }, []);

  return (
    <div className="body_wrap" style={{ display: "" }}>
      <div className="restaurant-header">
        <div className="cover_img" style={coverImageCss}></div>
      </div>
      {/* <div style={{ backgroundColor: "#80808033", height: "100%" }}>test</div> */}
      <div className="container">
        <div className="main-pay center-pay">
          <main className="content-pay mb-2" style={{ padding: "10px" }}>
            <div className="d-flex justify-content-between pt-2 pb-2 border-b">
              <span className="pay-details common-font-family">{t("total")}</span>
              <span className="pay-details common-font-family">
                {numberFormat(order_details_pay.total_net)}
              </span>
            </div>
            <div className="d-flex justify-content-between pt-2 pb-2 border-b">
              <span className="pay-details common-font-family">IVA</span>
              <span className="pay-details common-font-family">
                {numberFormat(order_details_pay.tax_amount)}
              </span>
            </div>
            <div className="d-flex justify-content-between pt-2 pb-2 border-b">
              <span className="pay-details common-font-family">{t("discount")}</span>
              <span className="pay-details common-font-family">
                {numberFormat(order_details_pay.discount)}
              </span>
            </div>
            <div className="d-flex justify-content-between pt-2 pb-2">
              <span className="pay-details common-font-family">{t("total")}</span>
              <span className="pay-details common-font-family">
                {numberFormat(order_details_pay.total)}
              </span>
            </div>
          </main>
          <div className="d-flex justify-content-center pt-4 pb-4">
            <button
              className="pay-btn takeaway_btn button ripple-effect"
              data-type="takeaway"
              onClick={() => {
                // localStorage.removeItem("order_details_pay");
                navigate("/kiosk");
                props.clearPage();
              }}
            >
              <div className="d-flex flex-column">
                <i className="fa fa-eur main-icon-pay" aria-hidden="true"></i>
                <span
                  style={{
                    color: "#4e4c4c",
                    fontWeight: "bolder",
                    fontSize: "14px",
                  }}
                  className="common-font-family"
                >
                  {t("cash")}
                </span>
              </div>
            </button>
            <button
              className="pay-btn takeaway_btn button"
              data-type="takeaway"
              onClick={() => {
                // localStorage.removeItem("order_details_pay");
                navigate("/kiosk");
                props.clearPage();
              }}
            >
              <div className="d-flex flex-column">
                <i
                  className="fa fa-credit-card-alt main-icon-pay"
                  aria-hidden="true"
                ></i>
                <span
                  style={{
                    color: "#4e4c4c",
                    fontWeight: "bolder",
                    fontSize: "14px",
                  }}
                  className="common-font-family"
                >
                  {t("pay_now")}
                </span>
              </div>
            </button>
          </div>
          <button
            className="btn btn-danger w-100 common-font-family"
            onClick={() => {
              localStorage.removeItem("order_details_pay");
              // navigate(-1);
                navigate("/kiosk");
              props.clearPage();
            }}
          >
            {t("cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PayAppIndex);
