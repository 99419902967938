import { LOAD_MENU_ITEMS, SET_ITEM_ID } from "../constants/actionTypes";

export const loadMenuItems = (menu_items) => {
  return { type: LOAD_MENU_ITEMS, menu_items: menu_items };
};

export const setItemID = (item_id) => {
  return { type: SET_ITEM_ID, item_id: item_id };
};

export const fetchMenuItems = () => (dispatch) => {
  let menu_items = JSON.parse(localStorage.getItem("menu_items"));
  dispatch(loadMenuItems(menu_items));
};
