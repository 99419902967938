const InitialStates = {
  categories: [],
  sub_categories: [],
  sub_cat_items: [],
  cat_id: 0,
};

const CategorySliderBar = (state = InitialStates, action) => {
  switch (action.type) {
    case "LOAD_ITEM_CATEGOTES":
      return {
        ...state,
        categories: action.categories,
      };
    case "LOAD_ITEM_SUB_CATEGOTES":
      return {
        ...state,
        sub_categories: action.sub_categories,
      };
    case "LOAD_SUB_CATE_ITEMS":
      return {
        ...state,
        sub_cat_items: action.sub_cat_items,
      };
    case "SEARCH_ITEM_BY_CATEGORY":
      return {
        ...state,
        cat_id: action.cat_id,
      };
    default:
      return state;
  }
};

export default CategorySliderBar;
