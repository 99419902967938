import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "../../assets/css/custom.css";
import "../../assets/css/style8e4c.css";
import "../../assets/css/common.css";
import "../../assets/css/responsive8e4c.css";
import "../../assets/css/themify-icons.css";
import "../../assets/css/metisMenu8e4c.css";
import "../../assets/css/style.css";
import "../../assets/css/spinner.css";
import "../../assets/css/kioskboard-2.1.0.min.css";
import "../../assets/css/toastr.css";
import "react-toastify/dist/ReactToastify.css";
import MainPage from "../Web/Main/MainPage";
import JustOrder from "../Web/JustOrder/JustOrder";
import Login from "../Web/Login/Login";
import PayAppIndex from "../Web/PaymentApp/PayAppIndex";
import CheckAuth from "./CheckAuth";

function Router() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/kiosk"
          element={
            <CheckAuth>
              <MainPage />
            </CheckAuth>
          }
        />
        <Route
          path="/justorder/:type"
          element={
            <CheckAuth>
              <JustOrder />
            </CheckAuth>
          }
        />
        <Route
          path="/pay/app"
          element={
            <CheckAuth>
              <PayAppIndex />
            </CheckAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
