const InitialStates = {
  item_details: [],
};

const ItemView = (state = InitialStates, action) => {
  switch (action.type) {
    case "LOAD_ITEM_DETAILS_DATA":
      return {
        ...state,
        item_details: action.item_details,
      };
    default:
      return state;
  }
};

export default ItemView;
