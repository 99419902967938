import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import takeAway from "../../../assets/images/icons/takeaway_icon.png";
// import dinein from "../../../assets/images/icons/dinein_icon.png";
import LoadingSpinner from "../Common/Spinner";
// import axios from "axios";
import { APP_URL } from "../../../constants/config";
import ApiHttp from "../../App/ApiHttp";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
// import { syncAllData } from "../../App/SyncData";
import showAlert from "../../App/SweetAlert";
import { useTranslation } from "react-i18next";
import { showSentMsgBox } from "../../../actions/PlaceOrder";
// import { syncAllData } from "../../../actions/Sync";

export const mapStateToProps = (state) => ({
  ...state,
  ...state.PlaceOrder,
  // ...state.Sync,
});

export const mapDispatchToProps = (dispatch) => ({
  showSentMsgBox: (show) => dispatch(showSentMsgBox(show)),
  // syncAllData: (run, now, min) => dispatch(syncAllData(run, now, min)),
});

const MainPage = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [welcomeImageCss, setWelocomeImageCss] = React.useState({
    background: "",
  });
  let lang = localStorage.getItem("lang");

  React.useEffect(() => {
    localStorage.removeItem("tabel_index");
    localStorage.removeItem("check_table_pop");
    fetchSettings();
    fetchCategoryData();
    // props.syncAllData(true, true, 0);
    if (props.show) {
      showAlert("success", "SENT SUCCESSFULLY");
      props.showSentMsgBox(false);
    }
  }, []);

  // props.syncAllData(true, false, 1);

  const fetchCategoryData = () => {
    setIsLoading(true);
    localStorage.removeItem("categories");
    ApiHttp.get(APP_URL + "kiosk_api.php?action=getCategories")
      .then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("categories", JSON.stringify(res.data.data));
          // fetchMenuItemData();
          fetchMenuItemDetailsData();
        }
      })
      .catch((err) => {});
  };

  // const fetchMenuItemData = () => {
  //   localStorage.removeItem("menu_items");
  //   axios
  //     .get("./dummy_data/menu_item.json")
  //     .then((res) => {
  //       localStorage.setItem("menu_items", JSON.stringify(res.data));
  //       fetchMenuItemDetailsData();
  //     })
  //     .catch((err) => {});
  // };

  const fetchMenuItemDetailsData = () => {
    localStorage.removeItem("item_details");
    ApiHttp.get(APP_URL + "kiosk_api.php?action=getItems")
      .then((res) => {
        if (res.data.status === 200) {
          fetchItemByCategories(res);
          localStorage.setItem("item_details", JSON.stringify(res.data.data));
          // setIsLoading(false);
        }
      })
      .catch((err) => {});
    // axios
    //   .get("./dummy_data/item_details.json")
    //   .then((res) => {
    //     fetchItemByCategories(res);
    //     localStorage.setItem("item_details", JSON.stringify(res.data));
    //     setIsLoading(false);
    //   })
    //   .catch((err) => {});
  };

  const fetchSettings = () => {
    localStorage.removeItem("app_settings");
    ApiHttp.get(APP_URL + "kiosk_api.php?action=settings")
      .then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("app_settings", JSON.stringify(res.data.data));
          let settings = res.data.data;
          setWelocomeImageCss({
            background: "url(" + settings.kioski_image + ") no-repeat",
          });
          document.documentElement.style.setProperty(
            "--theme-color-1",
            settings.restaurant_color
          );
          // setIsLoading(false);
        }
      })
      .catch((err) => {});
  };

  const onHandleLang = (e) => {
    localStorage.setItem("lang", e);
    window.location.reload();
  };

  const fetchItemByCategories = (res) => {
    setIsLoading(true);
    localStorage.removeItem("menu_items");
    localStorage.removeItem("menu_sub_items");
    localStorage.removeItem("sub_cat_items");
    let cateItemObj = {};
    let subCateItemObj = {};
    let categories = JSON.parse(localStorage.getItem("categories"));
    for (const key in categories) {
      cateItemObj[categories[key]["cat_id"]] = [];
      subCateItemObj[categories[key]["cat_id"]] = [];
      for (const item_id in res.data.data) {
        if (categories[key]["cat_id"] === res.data.data[item_id].cat_id) {
          cateItemObj[categories[key]["cat_id"]].push(res.data.data[item_id]);
        }
      }
      getSubCat(categories[key]["cat_id"],subCateItemObj,res.data.data);
    }
    localStorage.setItem("menu_items", JSON.stringify(cateItemObj));
    setIsLoading(false);
  };

  const getSubCat = (cat_id, subCateItemObj,items) => {
    setIsLoading(true);
    let subCatItems = {};
    ApiHttp.get(
      APP_URL + `kiosk_api.php?action=getSubCategories&parent_id=${cat_id}`
    )
      .then((resN) => {
        if (resN.data.status === 200) {
          if (resN.data.data) {
            for (const id in resN.data.data) {
              subCatItems[resN.data.data[id].cat_id] = [];
              subCateItemObj[cat_id].push(resN.data.data[id]);
              for (const item_id in items) {
                if (resN.data.data[id].cat_id === items[item_id].cat_id) {
                  subCatItems[resN.data.data[id].cat_id].push(items[item_id]);
                }
                localStorage.setItem("sub_cat_items", JSON.stringify(subCatItems));
              }
            }
            localStorage.setItem("menu_sub_items", JSON.stringify(subCateItemObj));
          }
        }
      })
      .catch((err) => {});
      setIsLoading(false);
  };

  return (
    <div>
      <div className="kiosk_welcome" style={welcomeImageCss}>
        {renderLangDrop(onHandleLang, lang)}
        <div className="kiosk_welcome_wrapper">
          {isLoading ? <LoadingSpinner /> : null}
          <Link to={"/justorder/take-away"}>
            <button
              className="kiosk_welcome_wrapper_button takeaway_btn button ripple-effect"
              data-type="takeaway"
            >
              {/* <img src={takeAway} alt="takeaway" /> */}
              <div className="d-flex flex-column">
                <i
                  className="fa fa-shopping-bag main-icon"
                  aria-hidden="true"
                ></i>
                <span
                  className="common-font-family"
                  style={{ color: "#4e4c4c", fontWeight: "500" }}
                >
                  {t("take_away")}
                </span>
              </div>
            </button>
          </Link>
          <Link to={"/justorder/dinein"}>
            <button
              className="kiosk_welcome_wrapper_button button ripple-effect dine_in_btn"
              data-type="on-table"
            >
              {/* <img src={dinein} alt="on-table" /> */}
              <div className="d-flex flex-column">
                <i className="fa fa-cutlery main-icon" aria-hidden="true"></i>
                <span
                  className="common-font-family"
                  style={{ color: "#4e4c4c", fontWeight: "500" }}
                >
                  {t("dine_in")}
                </span>
              </div>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

const renderLangDrop = (onHandleLang, lang) => {
  return (
    <div style={{ padding: "4px" }}>
      <DropdownButton
        title={
          !lang ? (
            <span className="flag-icon flag-icon-it"></span>
          ) : lang === "en" ? (
            <span className="flag-icon flag-icon-us"></span>
          ) : (
            <span className="flag-icon flag-icon-it"></span>
          )
        }
        id="dropdown-menu-align-right"
        onSelect={onHandleLang}
      >
        <Dropdown.Item eventKey="it" className="resize-item">
          <span
            style={{ fontSize: "30px" }}
            className="flag-icon flag-icon-it"
          ></span>{" "}
          <span style={{ fontSize: "18px" }}>Italy</span>
        </Dropdown.Item>
        <Dropdown.Item eventKey="en" className="resize-item">
          <span
            style={{ fontSize: "30px" }}
            className="flag-icon flag-icon-us"
          ></span>{" "}
          <span style={{ fontSize: "18px" }}>English</span>
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
