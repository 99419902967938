const InitialStates = {
    status: false,
  };
  
  const PlaceOrder = (state = InitialStates, action) => {
    switch (action.type) {
      case "CHECK_TABLE_POPUP_IS_OPEND":
        return {
          ...state,
          status: action.status,
        };
      default:
        return state;
    }
  };
  
  export default PlaceOrder;
  