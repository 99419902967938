import {
  OPEN_ITEM_ADD_TO_CART_VIEW,
  SET_ITEM_FOR_CART,
  REMOVE_ITEM_FROM_CART,
  CART_PAGE_CLEAR,
} from "../constants/actionTypes";

export const setOpenAddToCart = (name) => {
  return { type: OPEN_ITEM_ADD_TO_CART_VIEW, open: name };
};

export const setItemForCart = (items) => {
  return { type: SET_ITEM_FOR_CART, items: items };
};

export const removeItemFromCart = (id) => {
  return { type: REMOVE_ITEM_FROM_CART, id: id };
};

export const clearPage = () => {
  return { type: CART_PAGE_CLEAR };
};
