import axios from "axios";
import { APP_URL } from '../../constants/config';
const ApiHttp = axios.create({
  baseURL: APP_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});
if (localStorage.getItem("user_token"))
  ApiHttp.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("user_token")}`;

export default ApiHttp;
