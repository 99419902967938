import i18next from "i18next";
import { initReactI18next } from "react-i18next";

if (!localStorage.getItem("lang")) {
  localStorage.setItem("lang", "it");
}
let lang = localStorage.getItem("lang");
const resources = {
  en: {
    translation: {
      total_net: "Total",
      total: "Total",
      apply: "apply",
      coupons: "Coupons",
      cart_items: "Cart Items",
      place_order: "Place an order",
      discount: "Discount",
      skip: "Skip",
      continue: "Continue",
      to_add: "To Add",
      to_pay: "To pay",
      go_back: "Go back",
      take_away: "Takeaway",
      dine_in: "Dine in",
      coupon_valid: "Valid coupon",
      coupon_invalid: "Invalid coupon",
      pay_now: "Paynow",
      cash: "Cash",
      cancel: "Cancel",

      //messages
      add_success_msg: "Added to the shoppping cart",
      sent_sceess_msg: "SENT SUCCESSFULLY",
      place_order_msg: "Placed your order successfully",
      place_order_err_msg: "Something went wrong with your order",
    },
  },

  it: {
    translation: {
      total_net: "Totale Netto",
      total: "Totale",
      apply: "Applicare",
      coupons: "Buone",
      cart_items: "Articoli del carrello",
      place_order: "Effettua un ordine",
      discount: "Sconto",
      skip: "Salta",
      continue: "Continua",
      to_add: "Aggiungere",
      to_pay: "Pagare",
      go_back: "Torna indietro",
      take_away: "Porta via",
      dine_in: "Cenare in",
      coupon_valid: "Coupon valido",
      coupon_invalid: "Coupon non valido",
      pay_now: "Paga ora",
      cash: "Contanti",
      cancel: "Annulla",

      //messages
      add_success_msg: "Aggiunto al carrello",
      sent_sceess_msg: "Inviato con successo",
      place_order_msg: "Hai effettuato l'ordine con successo",
      place_order_err_msg: "Qualcosa è andato storto con il tuo ordine",
    },
  },
};

i18next

  .use(initReactI18next)

  .init({
    resources,

    lng: lang ? lang : "it",

    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
