import * as React from "react";
import testcatone from "../../../assets/images/menu/165580051962b182c7dcc1f.png";
import { connect } from "react-redux";
import { fetchItemDetails } from "../../../actions/ItemView";
import { setItemID } from "../../../actions/MenuItems";
import {
  setItemForCart,
  setOpenAddToCart,
} from "../../../actions/ItemAddToCart";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { numberFormat } from "../../App/Currency";
import { MENU_IMG_URL } from "../../../constants/config";

export const mapStateToProps = (state) => ({
  ...state,
  ...state.ItemView,
  ...state.MenuItems,
  ...state.ItemAddToCart,
});

export const mapDispatchToProps = (dispatch) => ({
  setOpenAddToCart: (open) => dispatch(setOpenAddToCart(open)),
  loadItemDetails: () => dispatch(fetchItemDetails()),
  setItemForCart: (items) => dispatch(setItemForCart(items)),
  setItemID: (item_id) => dispatch(setItemID(item_id)),
});

const ItemView = (props) => {
  let { type } = useParams();
  const { t } = useTranslation();
  const [qty, setQty] = React.useState(1);
  const [allVariants, setAllVariants] = React.useState([]);
  const [availableVariantOptions, setAvailableVariantOptions] = React.useState(
    []
  );
  const [variantOption, setVariantOption] = React.useState({});
  const [variantOptionStr, setVariantOptionStr] = React.useState({});
  const [cartItem, setCartItem] = React.useState([]);
  const [extra, setExtra] = React.useState([]);
  // const [potentialItemVariant, setPotentialItemVariant] = React.useState("");
  const [totalTax, setTotalTax] = React.useState(0);
  const [itempriceWithTax, setItempriceWithTax] = React.useState(0);
  const [defaultCheck, setDefaultCheck] = React.useState(0);
  const [note, setNote] = React.useState("");

  React.useEffect(() => {
    props.loadItemDetails();
  }, []);

  React.useEffect(() => {
    if (props.item_id > 0) {
      getVariants();
      calItemPriceWithTax(props.item_details[props.item_id]);
      let variants = props.item_details[props.item_id].variant_options[0];
      if (variants)
        handleVariantOptions(0, variants.id, JSON.parse(variants.options)[0]);
    }
    // setPotentialItemVariant("");
  }, [props.item_id]);

  const handleAddtoCart = () => {
    setTotalTax(0);
    setVariantOption({});
    setVariantOptionStr({});
    setExtra([]);
    setQty(1);
    setNote("");

    let newOptionStrt = "";
    for (const key in variantOptionStr) {
      newOptionStrt += key + "-" + variantOptionStr[key] + "-";
    }

    let found_item_variant = {};
    found_item_variant = allVariants.find((element) => {
      return element.key === newOptionStrt;
    });

    let mainArr = {};
    let price_tax = itempriceWithTax;
    mainArr.order_type = type;
    mainArr.table_index = localStorage.getItem("tabel_index");
    mainArr.id = props.item_id;
    mainArr.title = transLngMenuItemName(props.item_details[props.item_id]);
    mainArr.quantity = qty;
    mainArr.price = price_tax;
    mainArr.tax = props.item_details[props.item_id]["tax"];
    mainArr.variants =
      found_item_variant !== undefined ? found_item_variant.id : "";
    mainArr.variant_details =
      found_item_variant !== undefined ? found_item_variant : {};
    mainArr.total = calItemTotal();
    mainArr.variantOption = variantOption;
    mainArr.extras = extra;
    mainArr.extras_tot = totalTax;
    if (props.item_details[props.item_id]["tax"]) {
      if (found_item_variant !== undefined) {
        mainArr.total_tax =
          ((parseFloat(found_item_variant.price) *
            parseFloat(props.item_details[props.item_id]["tax"])) /
            100) *
          qty;
      } else {
        mainArr.total_tax =
          ((parseFloat(props.item_details[props.item_id]["price"]) *
            parseFloat(props.item_details[props.item_id]["tax"])) /
            100) *
          qty;
      }
    } else {
      mainArr.total_tax = 0;
    }
    mainArr.note = note;
    mainArr.image = MENU_IMG_URL + props.item_details[props.item_id].image;
    // console.log(mainArr);
    props.setItemForCart(mainArr);
    setCartItem([...cartItem, mainArr]);
    props.setItemID(0);
    props.handleToast();
    props.setOpenAddToCart(false);
  };

  const handleVariantOptions = (index, option_id, value) => {
    setDefaultCheck(index);
    // setPotentialItemVariant(
    //   (previous) => (previous += option_id + "-" + index + "-")
    // );
    setVariantOption({ ...variantOption, [option_id]: value });
    setVariantOptionStr({ ...variantOptionStr, [option_id]: index });
  };

  const handleExtra = (e, data, id) => {
    if (e.target.checked) {
      setExtra([
        ...extra,
        {
          id: data.id,
          title: transLngExtras(data),
          tax: data.tax,
          price: data.price,
        },
      ]);
      // if (data.tax) {
      //   setTotalTax(
      //     (previous) =>
      //       (previous += (parseFloat(data.price) * parseFloat(data.tax)) / 100)
      //   );
      // } else {
      setTotalTax((previous) => (previous += parseFloat(data.price)));
      // }
    } else {
      setExtra((previous) => previous.filter((data) => data.id !== id));
      // if (data.tax) {
      //   setTotalTax(
      //     (previous) =>
      //       (previous -= (parseFloat(data.price) * parseFloat(data.tax)) / 100)
      //   );
      // } else {
      setTotalTax((previous) => (previous -= parseFloat(data.price)));
      // }
    }
  };

  const calExtraPrice = () => {
    return extra.reduce(
      (price, extra) => (price = price + parseFloat(extra.price)),
      0
    );
  };

  const calItemTotal = () => {
    return (calExtraPrice() + getPriceByVariant()) * qty;
  };

  const calItemPriceWithTax = (data) => {
    let tax_amount = 0;
    if (data.tax) {
      tax_amount = (parseFloat(data.price) * parseFloat(data.tax)) / 100;
    }
    setItempriceWithTax(parseFloat(data.price) + tax_amount);
  };

  const getVariants = () => {
    // let selected_variant = null;
    let all_variants = [];
    let available_variant_options = [];

    let variants = props.item_details[props.item_id]["variants"];
    variants.map(function(variant, key) {
      var variants_key = "";
      var variantArr = JSON.parse(variant.options);
      for (const key in variantArr) {
        available_variant_options.push(key + "-" + variantArr[key]);
        setAvailableVariantOptions(available_variant_options);
        variants_key += key + "-" + variantArr[key] + "-";
      }
      all_variants.push({
        id: variant["id"],
        price: variant["price"],
        tax: variant["tax"],
        key: variants_key,
      });
      return all_variants;
    });
    setAllVariants(all_variants);
  };

  const handleToPay = () => {
    handleAddtoCart();
    props.setItemID(0);
    props.handleShowCart();
    props.handleClose();
    // console.log(allVariants);
    console.log(availableVariantOptions);
    // console.log(potentialItemVariant);
    // console.log(variantOption);
    // console.log(found_item_variant);
    // console.log(totalTax);
  };

  const transLngMenuItemName = (data) => {
    let lang = localStorage.getItem("lang");
    let trans = JSON.parse(data.translation);
    let item_name = "";

    if (lang) {
      if (lang === "en") {
        if (trans.en) {
          item_name = trans.en.title;
        } else {
          item_name = data.name;
        }
      }

      if (lang === "it") {
        if (trans.it) {
          item_name = trans.it.title;
        } else {
          item_name = data.name;
        }
      }
    } else {
      item_name = data.name;
    }

    return item_name;
  };

  const transLngItemDesc = (data) => {
    let lang = localStorage.getItem("lang");
    let trans = JSON.parse(data.translation);
    let desc = "";

    if (lang) {
      if (lang === "en") {
        if (trans.en) {
          desc = trans.en.description;
        } else {
          desc = data.title;
        }
      }

      if (lang === "it") {
        if (trans.it) {
          desc = trans.it.description;
        } else {
          desc = data.title;
        }
      }
    } else {
      desc = data.description;
    }

    return desc;
  };

  const transLngVariant = (data) => {
    let lang = localStorage.getItem("lang");
    let trans = JSON.parse(data.translation);
    let title = "";

    if (lang) {
      if (lang === "en") {
        if (trans.en) {
          title = trans.en.title;
        } else {
          title = data.title;
        }
      }

      if (lang === "it") {
        if (trans.it) {
          title = trans.it.title;
        } else {
          title = data.title;
        }
      }
    } else {
      title = data.title;
    }

    return title;
  };

  const transLngVariantOptions = (data) => {
    let lang = localStorage.getItem("lang");
    let trans = JSON.parse(data.translation);
    let options = JSON.parse(data.options);
    let optionsArr = [];

    if (lang) {
      if (lang === "en") {
        if (trans.en) {
          optionsArr = trans.en.options;
        } else {
          optionsArr = options;
        }
      }

      if (lang === "it") {
        if (trans.it) {
          optionsArr = trans.it.options;
        } else {
          optionsArr = options;
        }
      }
    } else {
      optionsArr = options;
    }

    return optionsArr;
  };

  const transLngExtras = (data) => {
    let lang = localStorage.getItem("lang");
    let trans = JSON.parse(data.translation);
    let title = "";

    if (lang) {
      if (lang === "en") {
        if (trans.en) {
          title = trans.en.title;
        } else {
          title = data.title;
        }
      }

      if (lang === "it") {
        if (trans.it) {
          title = trans.it.title;
        } else {
          title = data.title;
        }
      }
    } else {
      title = data.title;
    }

    return title;
  };

  const getPriceByVariant = () => {
    let newOptionStrt = "";
    for (const key in variantOptionStr) {
      newOptionStrt += key + "-" + variantOptionStr[key] + "-";
    }

    let found_item_variant = {};
    found_item_variant = allVariants.find((element) => {
      return element.key === newOptionStrt;
    });

    if (found_item_variant !== undefined) {
      if (props.item_details[props.item_id]["tax"]) {
        let tax_price =
          (parseFloat(found_item_variant.price) *
            parseFloat(props.item_details[props.item_id]["tax"])) /
          100;

        return parseFloat(found_item_variant.price) + tax_price;
      } else {
        return parseFloat(found_item_variant.price);
      }
    } else {
      return itempriceWithTax;
    }
  };

  const disableVariant = (data, index) => {
    let variants = props.item_details[props.item_id]["variants"];
    let found_variant = variants.find((element) => {
      let options = JSON.parse(element.options);
      return parseInt(options[data.id]) === parseInt(index);
    });

    if (found_variant === undefined) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      className="slide-bar"
      style={{ display: props.show ? "block" : "none" }}
    >
      {props.item_details[props.item_id] ? (
        <div className="cart_sidebar">
          <div className="cart_img">
            <img
              // className="menu_img"
              className={
                props.item_details[props.item_id].image === "default.png"
                  ? "menu_img set-gray"
                  : "menu_img"
              }
              alt=""
              src={
                props.item_details[props.item_id].image
                  ? MENU_IMG_URL + props.item_details[props.item_id].image
                  : testcatone
              }
              // src={testcatone}
            />
          </div>
          <div className="cart_details">
            <h2 className="heading_title text-uppercase menu_title">
              {transLngMenuItemName(props.item_details[props.item_id])}
            </h2>
            <div className="cart_items_list">
              <div className="cart_item">
                <div className="item_content">
                  <h4 className="item_title menu_desc">
                    {transLngItemDesc(props.item_details[props.item_id])}
                  </h4>
                  <span className="item_price menu_price common-font-family">
                    {/* {itempriceWithTax.toFixed(2)} € */}
                    {numberFormat(getPriceByVariant())}
                  </span>
                </div>
              </div>
            </div>
            {/* Variant Options */}
            {props.item_details[props.item_id]["variant_options"].length > 0 ? (
              <div id="menu-variants">
                {Object.values(
                  props.item_details[props.item_id]["variant_options"]
                ).map((data, key) => {
                  return [
                    <div className="menu-data" key={"v_option_" + key}>
                      <div className="section-headline margin-bottom-12">
                        <h5 className="variant-option-title common-font-family">
                          {transLngVariant(data)}
                        </h5>
                      </div>
                      <div className="menu-variant-options">
                        <div className="d-flex flex-column menu-variant-option">
                          {transLngVariantOptions(data).map(function(item, i) {
                            return [
                              <div key={"option_" + i}>
                                <div
                                  className="radio"
                                  style={{
                                    opacity: disableVariant(data, i)
                                      ? "0.3"
                                      : "",
                                  }}
                                >
                                  <input
                                    id={"radio-" + data.id + "-" + i}
                                    type="radio"
                                    name={data.title}
                                    value={item}
                                    checked={defaultCheck === i}
                                    disabled={disableVariant(data, i)}
                                    onChange={() =>
                                      handleVariantOptions(i, data.id, item)
                                    }
                                  />
                                  <label htmlFor={"radio-" + data.id + "-" + i}>
                                    <span className="radio-label"></span>{" "}
                                    <span className="variant-option common-font-family">
                                      {item}
                                    </span>
                                  </label>
                                </div>
                              </div>,
                            ];
                          })}
                        </div>
                      </div>
                    </div>,
                  ];
                })}
              </div>
            ) : null}
            {/* Extras */}
            {props.item_details[props.item_id]["extras"].length > 0 ? (
              <div className="menu-data menu-extra-wrapper">
                <div className="section-headline">
                  <h5 className="extra-option-title common-font-family">Extra</h5>
                </div>
                {props.item_details[props.item_id]["extras"].map(function(
                  item,
                  i
                ) {
                  return [
                    <div id="menu-extra-items" key={"extra_" + i}>
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          id={"checkbox" + item.id}
                          onChange={(e) => handleExtra(e, item, item.id)}
                        />
                        <label htmlFor={"checkbox" + item.id}>
                          <span className="checkbox-icon"></span>
                          <span className="extra-item-title common-font-family">
                            {transLngExtras(item)}
                          </span>
                          <strong className="margin-left-auto extra-item-price common-font-family">
                            {numberFormat(item.price)}
                          </strong>
                        </label>
                      </div>
                    </div>,
                  ];
                })}
              </div>
            ) : null}
            {/* qty input */}
            <div className="qr-input-number">
              <span
                role="button"
                className="qr-input-number__decrease is-disabled ripple-effect ripple-effect-dark common-font-family"
                id="menu-order-quantity-decrease"
                onClick={() => setQty((prevCount) => prevCount - 1)}
              >
                -
              </span>
              <div className="qr-input">
                <input
                  type="text"
                  className="qr-input__inner with-border common-font-family"
                  value={qty}
                  id="menu-order-quantity"
                  readOnly
                />
              </div>
              <span
                role="button"
                className="qr-input-number__increase ripple-effect ripple-effect-dark common-font-family"
                id="menu-order-quantity-increase"
                onClick={() => setQty((prevCount) => prevCount + 1)}
              >
                +
              </span>
            </div>
            <div className="qr-input-number d-none">
              <div className="section-headline">
                <h5 className="extra-option-title" id="title_cat">
                  Specificaties (met ajuin, zonder tomaat...)
                </h5>
              </div>
              <div className="qr-input">
                <textarea
                  className="form-control"
                  id="product_note"
                  onChange={(e) => setNote(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="total_price text-uppercase">
              <span className="common-font-family">{t("total")}:</span>
              <span id="order-price common-font-family">{numberFormat(calItemTotal())}</span>
            </div>

            <div className="sidebar_bar_btn">
              <button
                onClick={() => handleAddtoCart()}
                className="thm_btn text-uppercase common-font-family"
                id="add-order-button"
              >
                {t("to_add")}
              </button>
              <button
                ref={(el) => {
                  if (el) {
                    el.style.setProperty("background", "green", "important");
                  }
                }}
                className="thm_btn text-uppercase mt-3 cart_btn common-font-family"
                style={{ width: "100%" }}
                onClick={handleToPay}
              >
                {t("to_pay")}
              </button>
              <button
                onClick={() => {
                  props.handleClose();
                  setQty(1);
                  setNote("");
                  setTotalTax(0);
                  setVariantOption({});
                  setVariantOptionStr({});
                  setExtra([]);
                  props.setItemID(0);
                }}
                ref={(el) => {
                  if (el) {
                    el.style.setProperty("background", "red", "important");
                  }
                }}
                className="thm_btn text-uppercase mt-3 side_bar_close common-font-family"
                style={{ width: "100%" }}
              >
                {t("go_back")}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemView);
