const InitialStates = {
  name: "Imalsha kalansooriya",
  open: false,
  items: [],
};

const itemAddToCart = (state = InitialStates, action) => {
  switch (action.type) {
    case "OPEN_ITEM_ADD_TO_CART_VIEW":
      return {
        ...state,
        open: action.open,
      };
    case "SET_ITEM_FOR_CART":
      return {
        ...state,
        items: [...state.items, action.items],
      };
    case "REMOVE_ITEM_FROM_CART":
      let arrNew = [...state.items];
      delete arrNew[action.id];
      let resetArr = arrNew.filter(function(){return true;});
      return {
        ...state,
        items: resetArr,
      };
    case "CART_PAGE_CLEAR":
          return InitialStates;
    default:
      return state;
  }
};

export default itemAddToCart;
