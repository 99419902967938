const InitialStates = {
  menu_items: [],
  item_id: 0,
};

const MenuItems = (state = InitialStates, action) => {
  switch (action.type) {
    case "LOAD_MENU_ITEMS":
      return {
        ...state,
        menu_items: action.menu_items,
      };
    case "SET_ITEM_ID":
      return {
        ...state,
        item_id: action.item_id,
      };
    default:
      return state;
  }
};

export default MenuItems;
