// Item Add to cart view
export const OPEN_ITEM_ADD_TO_CART_VIEW = "OPEN_ITEM_ADD_TO_CART_VIEW";
export const SET_ITEM_FOR_CART = "SET_ITEM_FOR_CART";
export const CART_PAGE_CLEAR = "CART_PAGE_CLEAR";

// item category
export const LOAD_ITEM_CATEGOTES = "LOAD_ITEM_CATEGOTES";
export const SEARCH_ITEM_BY_CATEGORY = "SEARCH_ITEM_BY_CATEGORY";
export const LOAD_ITEM_SUB_CATEGOTES = "LOAD_ITEM_SUB_CATEGOTES";
export const LOAD_SUB_CATE_ITEMS = "LOAD_SUB_CATE_ITEMS";

// menu items
export const LOAD_MENU_ITEMS = "LOAD_MENU_ITEMS";
export const SET_ITEM_ID = "SET_ITEM_ID";

// item details for add to cart
export const LOAD_ITEM_DETAILS_DATA = "LOAD_ITEM_DETAILS_DATA";
export const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART";

// place order
export const SHOW_SENT_ORDER_MSG = "SHOW_SENT_ORDER_MSG";

// Sync data
export const LOADING_SYNC_DATA = "LOADING_SYNC_DATA";

// general
export const CHECK_TABLE_POPUP_IS_OPEND = "CHECK_TABLE_POPUP_IS_OPEND";
