import {
  LOAD_ITEM_CATEGOTES,
  SEARCH_ITEM_BY_CATEGORY,
  LOAD_ITEM_SUB_CATEGOTES,
  LOAD_SUB_CATE_ITEMS
} from "../constants/actionTypes";

export const loadCategories = (categories) => {
  return { type: LOAD_ITEM_CATEGOTES, categories: categories };
};

export const loadSubCategories = (sub_categories) => {
  return { type: LOAD_ITEM_SUB_CATEGOTES, sub_categories: sub_categories };
};

export const loadItemDetails = (sub_cat_items) => {
  return { type: LOAD_SUB_CATE_ITEMS, sub_cat_items: sub_cat_items };
};

export const searchItemByCategory = (cat_id) => {
  return { type: SEARCH_ITEM_BY_CATEGORY, cat_id: cat_id };
};

export const fetchCategories = () => (dispatch) => {
  let categories = JSON.parse(localStorage.getItem("categories"));
  dispatch(loadCategories(categories));
};

export const fetchSubCateItems = () => (dispatch) => {
  let sub_categories = JSON.parse(localStorage.getItem("menu_sub_items"));
  dispatch(loadSubCategories(sub_categories));
};

export const fetchItemDetails = () => (dispatch) => {
  let sub_cat_items = JSON.parse(localStorage.getItem("sub_cat_items"));
  dispatch(loadItemDetails(sub_cat_items));
};
