import React, { useState, useEffect } from "react";
import testcatone from "../../../assets/images/default.png";
import { connect } from "react-redux";
import {
  fetchCategories,
  searchItemByCategory,
} from "../../../actions/CategorySliderBar";
import { CAT_IMG_URL } from "../../../constants/config";

export const mapStateToProps = (state) => ({
  ...state,
  ...state.CategorySliderBar,
});

export const mapDispatchToProps = (dispatch) => ({
  loadCategories: () => dispatch(fetchCategories()),
  setCatID: (cat_id) => dispatch(searchItemByCategory(cat_id)),
});

const CategorySlideBar = (props) => {
  useEffect(() => {
    props.loadCategories();
    // if (props.cat_id > 0) {
    //   props.setCatID(0);
    // }
  }, []);

  useEffect(() => {
    getFirstCat();
  }, [props.categories]);

  const [selectKey, setSelectKey] = useState(0);

  const changeSelect = (key) => {
    setSelectKey(key);
    props.setCatID(key);
  };

  const getFirstCat = () => {
    if (Object.values(props.categories).length > 0) {
      let first_cat = props.categories[0].cat_id;
      props.setCatID(first_cat);
      setSelectKey(first_cat);
    }
  };

  const transLngCatName = (data) => {
    let lang = localStorage.getItem("lang");
    let trans = JSON.parse(data.translation);
    let cat_name = "";

    if (lang) {
      if (lang === "en") {
        if (trans.en) {
          cat_name = trans.en.title;
        } else {
          cat_name = data.cat_name;
        }
      }

      if (lang === "it") {
        if (trans.it) {
          cat_name = trans.it.title;
        } else {
          cat_name = data.cat_name;
        }
      }
    } else {
      cat_name = data.cat_name;
    }

    return cat_name;
  };

  return (
    <div className="side_menu" style={{ width: "200px" }}>
      <div className="single-page-header-inner d-none">
        <div
          className="header-image filter-button"
          data-filter="gallery-show-all"
        >
          <img
            src="storage/restaurant/logo/165485577262a3185c5e2d5.png"
            alt=""
          />
        </div>
      </div>
      <div className="recipe_menu_wrap">
        <ul className="nav nav-tabs recipe_menu" id="myTab" role="tablist">
          {Object.values(props.categories).map((data, key) => {
            return [
              <li className="nav-item" role="presentation" key={"cate_" + key}>
                <a
                  href="#/"
                  className={
                    selectKey === data.cat_id
                      ? "nav-link filter-button active"
                      : "nav-link filter-button"
                  }
                  onClick={() => changeSelect(data.cat_id)}
                >
                  <span className={!data.picture ? "icon set-gray" : "icon"}>
                    <img
                      src={
                        data.picture ? CAT_IMG_URL + data.picture : testcatone
                      }
                      alt={data.picture}
                    />
                  </span>
                  <span className="title cus-font-family">
                    {transLngCatName(data)}
                  </span>
                </a>
              </li>,
            ];
          })}
        </ul>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CategorySlideBar);
