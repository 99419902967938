import * as React from "react";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../../assets/css/login.css";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import ApiHttp from "../../App/ApiHttp";

const Login = () => {
  const navigate = useNavigate();
  const [btnDisable, setBtnDisable] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    setBtnDisable(true);
    const user = {
      action: "login",
      email: email,
      password: password,
    };

    ApiHttp.post("kiosk_api.php", user)
      .then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("user_token", res.data.token);
          navigate("/kiosk");
          window.location.reload();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.msg, {
          position: toast.POSITION.TOP_CENTER,
        });
        setBtnDisable(false);
      });
  };

  return (
    <div>
      <div className="container mt-5">
        <div className="row d-flex justify-content-center">
          <div className="col-md-6">
            <div className="card px-5 py-5" id="form1">
              <div className="form-data">
                <div className="forms-inputs mb-4">
                  {" "}
                  <span>Email or username</span>{" "}
                  <input
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="forms-inputs mb-4">
                  {" "}
                  <span>Password</span>{" "}
                  <input
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  {" "}
                  <button
                    onClick={handleLogin}
                    style={{
                      backgroundColor: "#e8a952",
                      color: "white",
                      height: "50px",
                    }}
                    className="btn w-100"
                    disabled={btnDisable}
                  >
                    Login
                  </button>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        icon={false}
        toastStyle={{
          border: "solid 2px",
          // backgroundColor: "red",
          color: "white",
          borderColor: "red",
          borderRadius: "20px",
        }}
        hideProgressBar={true}
        closeButton={false}
        autoClose={1000}
      />
    </div>
  );
};

export default Login;
