import * as React from "react";
import { connect } from "react-redux";
import { removeItemFromCart, clearPage } from "../../../actions/ItemAddToCart";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ApiHttp from "../../App/ApiHttp";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "./Spinner";
import { numberFormat } from "../../App/Currency";
import { setCheckPopStatus } from "../../../actions/General";

export const mapStateToProps = (state) => ({
  ...state,
  ...state.ItemAddToCart,
  ...state.General,
});

export const mapDispatchToProps = (dispatch) => ({
  removeItemFromCart: (id) => dispatch(removeItemFromCart(id)),
  clearPage: () => dispatch(clearPage()),
  setCheckPopStatus: (status) => dispatch(setCheckPopStatus(status)),
});

const ItemCart = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { type } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [couponCode, setCouponCode] = React.useState("");
  const [discount, setDiscount] = React.useState(0);

  React.useEffect(() => {
    console.log(props.items);
  });

  const calCount = () => {
    return props.items.reduce((qty, item) => (qty = qty + item.quantity), 0);
  };

  const itemValuesCal = () => {
    let totOrderAmount = 0;
    let totTaxAmount = 0;
    for (const id of Object.keys(props.items)) {
      const { total, total_tax } = props.items[id];

      if (total) {
        totOrderAmount += parseFloat(total);
      }
      if (total_tax) {
        totTaxAmount += parseFloat(total_tax);
      }
    }

    return { totOrderAmount, totTaxAmount };
  };

  const handleRemove = (id) => {
    props.removeItemFromCart(id);
  };

  const getVariants = (item) => {
    const str = Object.values(item.variantOption).join(",");
    return str;
  };

  const handleCoupon = () => {
    const coupon = {
      action: "validateCoupon",
      coupon_code: couponCode,
      total: itemValuesCal().totOrderAmount,
    };

    ApiHttp.post("kiosk_api.php", coupon)
      .then((res) => {
        if (res.data.status === 200) {
          setDiscount(JSON.parse(res.data.data).discount);
          toast.success(t("coupon_valid"), {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        toast.error(t("coupon_invalid"), {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const handlePlaceOrder = () => {
    setIsLoading(true);
    const filterItems = props.items.map(
      ({
        order_type,
        table_index,
        variant_details,
        total,
        variantOption,
        total_tax,
        ...rest
      }) => {
        return rest;
      }
    );

    const orderDetails = {
      ordering_type: type === "dinein" ? "on-table" : "takeaway",
      table: localStorage.getItem("tabel_index"),
      discount_code: couponCode,
      total: calTotalWithDiscount(itemValuesCal().totOrderAmount),
      action: "sendOrder",
      items: JSON.stringify(filterItems),
      message: "",
    };

    const order_details_pay = {
      ordering_type: type === "dinein" ? "on-table" : "takeaway",
      table: localStorage.getItem("tabel_index"),
      total_net: itemValuesCal().totOrderAmount - itemValuesCal().totTaxAmount,
      tax_amount: itemValuesCal().totTaxAmount,
      discount: discount,
      total: calTotalWithDiscount(itemValuesCal().totOrderAmount),
    };

    // console.log(orderDetails);
    // localStorage.setItem(
    //   "order_details_pay",
    //   JSON.stringify(order_details_pay)
    // );
    // navigate("/pay/app");

    ApiHttp.post("kiosk_api.php", orderDetails)
      .then((res) => {
        if (res.data.status === 200) {
          // props.hadleShowSenOrderdMsg();
          toast.success(t("place_order_msg"), {
            position: toast.POSITION.TOP_CENTER,
          });
          // props.clearPage();
          localStorage.setItem(
            "order_details_pay",
            JSON.stringify(order_details_pay)
          );
          props.setCheckPopStatus(false);
          localStorage.removeItem("tabel_index");
          setIsLoading(false);
          navigate("/pay/app");
          // setTimeout(function() {
          //   navigate("/kiosk");
          // }, 2000);
        } else {
          toast.error(t("place_order_err_msg"), {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(t("place_order_err_msg"), {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const calTotalWithDiscount = (total) => {
    if (discount) {
      return total - discount;
    } else {
      return total;
    }
  };

  return (
    <aside
      className="slide-bar"
      style={{ display: props.show ? "block" : "none" }}
    >
      {isLoading ? <LoadingSpinner /> : null}
      <div className="cart_sidebar">
        <h2 className="heading_title text-uppercase set-size">
          {t("cart_items")} - <span id="cart-item-count">{calCount()}</span>
        </h2>
        <div className="cart_items_list your-order-items">
          {Object.values(props.items).map((item, key) => {
            return [
              <div className="cart_item" key={"cart_item" + key}>
                <div className="item_content">
                  <div className="d-flex flex-row">
                    <img
                      style={{ maxWidth: "66px", maxHeight: "66px" }}
                      src={item.image}
                      alt=""
                      className="pr-2"
                    />
                    <h4 className="item_title pt-2">
                      <span className="menu_title" style={{ fontSize: "16px" }}>
                        {item.title}{" "}
                        {item.quantity > 1 ? "x " + item.quantity : ""}{" "}
                        {Object.keys(item.variantOption).length > 0 ? (
                          <small>
                            <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>
                                ({getVariants(item)})
                              </font>
                            </font>
                          </small>
                        ) : null}
                      </span>
                      <span className="item_price set-size">
                        {numberFormat(
                          item.total - item.extras_tot * item.quantity
                        )}
                      </span>
                      <button
                        type="button"
                        className="remove_btn item-delete"
                        onClick={() => handleRemove(key)}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </h4>
                  </div>
                  <div
                    className="d-flex flex-row"
                    style={{ paddingLeft: "64px" }}
                  >
                    <div className="menu-data menu-extra-wrapper">
                      {item.extras.length > 0
                        ? item.extras.map(function(extra, i) {
                            return [
                              <div
                                className="menu-extra-item"
                                key={"cart_extra" + key}
                              >
                                <span
                                  className="extra-item-title common-font-family"
                                  style={{ fontSize: "16px" }}
                                >
                                  <font style={{ verticalAlign: "inherit" }}>
                                    <font style={{ verticalAlign: "inherit" }}>
                                      <font
                                        style={{ verticalAlign: "inherit" }}
                                      >
                                        <font
                                          style={{ verticalAlign: "inherit" }}
                                        >
                                          {extra.title}
                                        </font>
                                      </font>
                                    </font>
                                  </font>
                                </span>
                                <font style={{ verticalAlign: "inherit" }}>
                                  <font style={{ verticalAlign: "inherit" }}>
                                    :{" "}
                                  </font>
                                </font>
                                <strong className="margin-left-auto extra-item-price">
                                  <font style={{ verticalAlign: "inherit" }}>
                                    <font style={{ verticalAlign: "inherit" }}>
                                      {numberFormat(
                                        parseFloat(extra.price) * item.quantity
                                      )}
                                    </font>
                                  </font>
                                </strong>
                              </div>,
                            ];
                          })
                        : null}
                    </div>
                  </div>
                </div>
              </div>,
            ];
          })}
        </div>
        {props.items.length > 0 ? (
          <div className="coupon" style={{ display: "" }}>
            <span>{t("coupons")}</span>
            <input
              type="text"
              className="with-border mr-2"
              id="coupon"
              onChange={(e) => setCouponCode(e.target.value)}
            />
            <button
              id="apply_coupon"
              style={{
                display: "inline",
                color: "white",
              }}
              onClick={handleCoupon}
            >
              {/* {t("apply")} */}
              <i className="fa fa-check"></i>
            </button>
            {/* <button
              style={{
                color: "white",
                backgroundColor: "rgb(77 187 99)",
                display: "inline",
                width: "54px",
                height: "54px",
              }}
              class="btn"
              onClick={handleCoupon}
            >
              <i class="fa fa-check"></i>
            </button> */}
            <div id="coupon_errors"></div>
          </div>
        ) : null}
        <div className="cart_total_info">
          <div className="total_price text-uppercase common-font-family">
            <span>{t("total_net")}</span>
            <span className="your-order-price order-total" id="cart-toal">
              {numberFormat(
                itemValuesCal().totOrderAmount - itemValuesCal().totTaxAmount
              )}
            </span>
          </div>
          <div className="total_price text-uppercase common-font-family">
            <span>IVA</span>
            <span className="order-tax-total" id="cart-tax-total">
              {numberFormat(itemValuesCal().totTaxAmount)}
            </span>
          </div>
          <div className="total_price text-uppercase common-font-family">
            <span>{t("discount")}</span>
            <span className="order-discount" id="cart-discount">
              {discount ? `- ${numberFormat(discount)}` : "- 0,00 €"}
            </span>
          </div>
          <div className="total_price text-uppercase common-font-family">
            <span>{t("total")}</span>
            <span className="order-subtotal" id="cart-subtotal">
              {numberFormat(
                calTotalWithDiscount(itemValuesCal().totOrderAmount)
              )}
            </span>
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="thm_btn thm_btn-2 text-uppercase common-font-family"
            id="checkout"
            style={{ opacity: props.items.length > 0 ? "" : "0.3" }}
            disabled={props.items.length > 0 ? false : true}
            onClick={handlePlaceOrder}
          >
            {t("place_order")}
          </button>
          {/* <button
            ref={(el) => {
              if (el) {
                el.style.setProperty("background", "green", "important");
              }
            }}
            onClick={() => {
              console.log(props.items);
            }}
            type="button"
            className="thm_btn text-uppercase mt-3 thm_btn-2"
            id="payLater"
            style={{ width: "100%" }}
          >
            Betaal aan de kassa
          </button> */}
          <button
            ref={(el) => {
              if (el) {
                el.style.setProperty("background", "red", "important");
              }
            }}
            onClick={() => props.handleClose()}
            type="button"
            className="thm_btn text-uppercase mt-3 side_bar_close"
            style={{ width: "100%", fontSize: "25px" }}
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
      </div>
    </aside>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemCart);
