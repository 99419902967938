import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CategorySlideBar from "../Common/CategorySlideBar";
import ItemCart from "../Common/ItemCart";
import MenuItems from "../Common/MenuItems";
import ModalPopUp from "../Common/ModalPopUp";
import { connect } from "react-redux";
import { setOpenAddToCart } from "../../../actions/ItemAddToCart";
import { showSentMsgBox } from "../../../actions/PlaceOrder";
// import { setCheckPopStatus } from "../../../actions/General";
import ItemView from "../Common/ItemView";
import { useTranslation } from "react-i18next";

export const mapStateToProps = (state) => ({
  ...state,
  ...state.ItemAddToCart,
  ...state.PlaceOrder,
  // ...state.General,
});

export const mapDispatchToProps = (dispatch) => ({
  setOpenAddToCart: (open) => dispatch(setOpenAddToCart(open)),
  showSentMsgBox: (show) => dispatch(showSentMsgBox(show)),
  // setCheckPopStatus: (status) => dispatch(setCheckPopStatus(status)),
});

const JustOrder = (props) => {
  let { type } = useParams();
  const { t } = useTranslation();
  let settings = JSON.parse(localStorage.getItem("app_settings"));
  let check_table_pop = localStorage.getItem("check_table_pop");
  const [coverImageCss, setCoverImageCss] = React.useState({
    background: "",
  });

  const [show, setShow] = React.useState(false);
  const [showPopUp, setShowPopup] = React.useState(false);

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => setShow(false);
  const handleClosePopUp = () => {
    setShowPopup(false);
  };
  const hadleShowSenOrderdMsg = () => props.showSentMsgBox(true);

  const handleAddToCartClose = () => props.setOpenAddToCart(false);

  const handleToast = () => {
    toast.success(t("add_success_msg"), {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  React.useEffect(() => {
    setCoverImageCss({
      background: "url(" + settings.kioski_cover_image + ") no-repeat",
    });
    document.documentElement.style.setProperty(
      "--theme-color-1",
      settings.restaurant_color
    );
    if (type === "dinein") {
      if (!check_table_pop) setShowPopup(true);
    } else {
      // props.setCheckPopStatus(false);
    }
  }, []);

  const handleShowPopUp = () => {
    localStorage.setItem("check_table_pop", true);
    // props.setCheckPopStatus(true);
  };

  return (
    <div className="body_wrap" style={{ display: "" }}>
      <div className="restaurant-header fixed-header">
        <div className="cover_img" style={coverImageCss}></div>
      </div>
      <main>
        <div className="shop_area shop_sidebar p-3 pt-5">
          {/* <div className="container-fluid"> */}
          <div
            className="main_wrapper"
            style={{
              position: "relative",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "145px"
            }}
          >
            <CategorySlideBar />
            <MenuItems />
          </div>
          {/* </div> */}
        </div>
      </main>
      <div id="view-order-wrapper" style={{ display: "block" }}>
        <Link to={"/kiosk"}>
          <button id="cancel-order-button" className="button ripple-effect">
            <i
              className="fa fa-times"
              style={{
                fontSize: "40px",
                width: "auto",
                lineHeight: "40px",
                color: "#fff",
              }}
            ></i>
          </button>
        </Link>
        <button
          onClick={handleShow}
          id="view-order-button"
          className="button ripple-effect cart_btn"
        >
          <i
            className="fa fa-shopping-basket"
            style={{ fontSize: "40px", width: "auto", lineHeight: "40px" }}
          ></i>
        </button>
      </div>
      {show ? (
        <ItemCart
          show={show}
          handleClose={handleClose}
          hadleShowSenOrderdMsg={hadleShowSenOrderdMsg}
        />
      ) : null}
      {showPopUp ? (
        <ModalPopUp
          show={showPopUp}
          handleClose={handleClosePopUp}
          handleShowPopUp={handleShowPopUp}
        />
      ) : null}
      <ItemView
        show={props.open}
        handleClose={handleAddToCartClose}
        handleToast={handleToast}
        handleShowCart={handleShow}
      />
      <ToastContainer
        icon={false}
        toastStyle={{
          border: "solid 2px",
          borderColor: "white",
          borderRadius: "20px",
        }}
        hideProgressBar={true}
        autoClose={1000}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(JustOrder);
