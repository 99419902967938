import * as React from "react";
import Modal from "react-bootstrap/Modal";
import KioskBoard from "kioskboard";
import { useTranslation } from "react-i18next";

const ModalPopUp = (props) => {
  const { t } = useTranslation();
  const numpadRef = React.useRef(null);
  // const [table, setTable] = React.useState(0);

  React.useEffect(() => {
    if (numpadRef.current) {
      KioskBoard.run(numpadRef.current, {
        theme: "light",
        keysArrayOfObjects: [
          {
            "0": "7",
            "1": "8",
            "2": "9",
          },
          {
            "0": "4",
            "1": "5",
            "2": "6",
          },
          {
            "0": "1",
            "1": "2",
            "2": "3",
          },
          {
            "0": "0",
            "1": ".",
          },
        ],
      });
    }
  }, [numpadRef]);

  const handleClose = () => {
    localStorage.removeItem("tabel_index");
    props.handleShowPopUp();
    props.handleClose();
  };

  const handleContinue = () => {
    localStorage.setItem("tabel_index", numpadRef.current.value);
    props.handleShowPopUp();
    props.handleClose();
  };
  return (
    <Modal show={props.show} fullscreen onHide={handleClose} centered>
      <Modal.Body>
        <div className="pl-5 pr-5">
          {/* <h4>1 Pak een tafelnummer</h4>
          <h4>2 Vul kaartnummer</h4>
          <h4>3 Kies OK</h4> */}
        </div>
        <div className="input-group mb-3 mt-3 pl-5 pr-5">
          <input
            className="form-control js-kioskboard-input"
            ref={numpadRef}
            data-kioskboard-type="numpad"
            type="number"
            placeholder="00"
            // readOnly
            // value={table}
            // onChange={(e) => handleTable(e)}
            // autoFocus
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary cus-font-family"
          onClick={handleClose}
          // data-bs-dismiss="modal"
          // aria-label="Close"
        >
          {t("skip")}
        </button>
        <button
          type="button"
          className="btn btn-success cus-font-family"
          // data-bs-dismiss="modal"
          // aria-label="Close"
          onClick={handleContinue}
        >
          {t("continue")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPopUp;
