const InitialStates = {
    loading: false,
  };
  
  const PlaceOrder = (state = InitialStates, action) => {
    switch (action.type) {
      case "LOADING_SYNC_DATA":
        return {
          ...state,
          loading: action.loading,
        };
      default:
        return state;
    }
  };
  
  export default PlaceOrder;
  