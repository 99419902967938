import * as React from "react";
import { Navigate } from "react-router-dom";

const CheckAuth = ({ children }) => {
  //   const { authed } = useAuth();
  let authed = localStorage.getItem("user_token");

  return authed ? children : <Navigate to="/" replace />;
};

export default CheckAuth;
