import { LOAD_ITEM_DETAILS_DATA } from "../constants/actionTypes";

export const loadItemDetails = (item_details) => {
  return { type: LOAD_ITEM_DETAILS_DATA, item_details: item_details };
};

export const fetchItemDetails = () => (dispatch) => {
  let item_details = JSON.parse(localStorage.getItem("item_details"));
  dispatch(loadItemDetails(item_details));
};
