import React from "react";
import testcatone from "../../../assets/images/menu/165580051962b182c7dcc1f.png";
import { connect } from "react-redux";
import { setOpenAddToCart } from "../../../actions/ItemAddToCart";
import {
  fetchCategories,
  fetchSubCateItems,
  fetchItemDetails,
} from "../../../actions/CategorySliderBar";
import { fetchMenuItems, setItemID } from "../../../actions/MenuItems";
import { numberFormat } from "../../App/Currency";
import { MENU_IMG_URL } from "../../../constants/config";
// import ApiHttp from "../../App/ApiHttp";

export const mapStateToProps = (state) => ({
  ...state,
  ...state.ItemAddToCart,
  ...state.CategorySliderBar,
  ...state.MenuItems,
});

export const mapDispatchToProps = (dispatch) => ({
  setOpenAddToCart: (open) => dispatch(setOpenAddToCart(open)),
  loadCategories: () => dispatch(fetchCategories()),
  loadMenuItems: () => dispatch(fetchMenuItems()),
  setItemID: (item_id) => dispatch(setItemID(item_id)),
  fetchSubCateItems: () => dispatch(fetchSubCateItems()),
  fetchItemDetails: () => dispatch(fetchItemDetails()),
});

const MenuItems = (props) => {
  React.useEffect(() => {
    props.loadCategories();
    props.loadMenuItems();
    props.fetchSubCateItems();
    props.fetchItemDetails();
  }, []);

  const handleShow = (item_id) => {
    props.setOpenAddToCart(true);
    props.setItemID(item_id);
  };

  const calPriceWithTax = (data) => {
    let tax_amount = 0;
    if (data.tax) {
      tax_amount = (parseFloat(data.price) * parseFloat(data.tax)) / 100;
    }

    return numberFormat(parseFloat(data.price) + tax_amount);
  };

  const transLngCatName = (data) => {
    let lang = localStorage.getItem("lang");
    let trans = JSON.parse(data.translation);
    let cat_name = "";

    if (lang) {
      if (lang === "en") {
        if (trans.en) {
          cat_name = trans.en.title;
        } else {
          cat_name = data.cat_name;
        }
      }

      if (lang === "it") {
        if (trans.it) {
          cat_name = trans.it.title;
        } else {
          cat_name = data.cat_name;
        }
      }
    } else {
      cat_name = data.cat_name;
    }

    return cat_name;
  };

  const transLngMenuItemName = (data) => {
    let lang = localStorage.getItem("lang");
    let trans = JSON.parse(data.translation);
    let item_name = "";

    if (lang) {
      if (lang === "en") {
        if (trans.en) {
          item_name = trans.en.title;
        } else {
          item_name = data.name;
        }
      }

      if (lang === "it") {
        if (trans.it) {
          item_name = trans.it.title;
        } else {
          item_name = data.name;
        }
      }
    } else {
      item_name = data.name;
    }

    return item_name;
  };

  const renderMenuItems = (cat, key) => {
    return Object.values(props.menu_items[cat.cat_id]).length > 0 ? (
      <div className="item_wrapper side-menu-items" key={"cat_" + key}>
        {/* <div className="shop_sidebar_left"> */}
        <div className="boxed-list" data-category-image="Burgers">
          <h3 className="title mb-30 custom_menu_title">
            {transLngCatName(cat)}
          </h3>
          <div className="row">
            {Object.values(props.menu_items[cat.cat_id]).map((menu, key) => {
              return [
                <div
                  className="col-lg-4 col-md-6 col-sm-6 col-6 ajax-item-listing"
                  key={"menu_" + key}
                >
                  <div
                    className="shop_single white_bg mb-30 add-extras item-bg"
                    onClick={() => handleShow(menu.id)}
                  >
                    <div
                      className={
                        menu.image === "default.png"
                          ? "thumb text-center set-gray remove-item-bg"
                          : "thumb text-center remove-item-bg"
                      }
                    >
                      <img
                        src={
                          menu.image ? MENU_IMG_URL + menu.image : testcatone
                        }
                        // src={testcatone}
                        alt="menu"
                      />
                    </div>
                    <div className="content" style={{ textAlign: "center" }}>
                      <h3 className="title">{transLngMenuItemName(menu)}</h3>
                      {/* <div className="s_bottom ul_li" style={{ textAlign:'center' }}> */}
                      <span className="common-font-family item-de">
                        {calPriceWithTax(menu)}
                      </span>
                      {/* </div> */}
                    </div>
                  </div>
                </div>,
              ];
            })}
          </div>
        </div>
        {/* </div> */}
      </div>
    ) : null;
  };

  const renderMenuSubItems = (cat_id) => {
    // console.log(props.sub_categories[cat_id] !== "undefined") Object.values(props.sub_categories[3]).length
    return props.sub_categories[cat_id] !== undefined ? (
      <div className="item_wrapper side-menu-items">
        {Object.values(props.sub_categories[cat_id]).map((sub, key) => {
          return [
            <div
              className="boxed-list"
              data-category-image="Burgers"
              key={key + "_sub_cat"}
            >
              <h3 className="title mb-30 custom_menu_title">{sub.cat_name}</h3>
              {props.sub_cat_items !== undefined ? (
                <div className="row">
                  {Object.values(props.sub_cat_items[sub.cat_id]).map(
                    (menu, key) => {
                      return [
                        <div
                          className="col-lg-4 col-md-6 col-sm-6 col-6 ajax-item-listing"
                          key={"menu_" + key}
                        >
                          <div
                            className="shop_single white_bg mb-30 add-extras item-bg"
                            onClick={() => handleShow(menu.id)}
                          >
                            <div
                              className={
                                menu.image === "default.png"
                                  ? "thumb text-center set-gray remove-item-bg"
                                  : "thumb text-center remove-item-bg"
                              }
                            >
                              <img
                                src={
                                  menu.image
                                    ? MENU_IMG_URL + menu.image
                                    : testcatone
                                }
                                // src={testcatone}
                                alt="menu"
                              />
                            </div>
                            <div
                              className="content"
                              style={{ textAlign: "center" }}
                            >
                              <h3 className="title">
                                {transLngMenuItemName(menu)}
                              </h3>
                              {/* <div className="s_bottom ul_li" style={{ textAlign:'center' }}> */}
                              <span className="common-font-family item-de">
                                {calPriceWithTax(menu)}
                              </span>
                              {/* </div> */}
                            </div>
                          </div>
                        </div>,
                      ];
                    }
                  )}
                </div>
              ) : null}
            </div>,
          ];
        })}
      </div>
    ) : null;
  };

  return (
    // console.log(props.sub_categories[3]),
    <div>
      {Object.values(props.categories).map((cat, key) => {
        return [
          props.cat_id > 0
            ? props.cat_id === cat.cat_id
              ? renderMenuItems(cat, key)
              : null
            : renderMenuItems(cat, key),
        ];
      })}
      {renderMenuSubItems(props.cat_id)}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuItems);
