const InitialStates = {
  show: false,
};

const PlaceOrder = (state = InitialStates, action) => {
  switch (action.type) {
    case "SHOW_SENT_ORDER_MSG":
      return {
        ...state,
        show: action.show,
      };
    default:
      return state;
  }
};

export default PlaceOrder;
