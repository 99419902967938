// import { combineReducers } from 'redux'
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// import { configureStore } from '@reduxjs/toolkit'
import ItemAddToCart from "../reducers/ItemAddToCart";
import CategorySliderBar from "../reducers/CategorySliderBar";
import MenuItems from "../reducers/MenuItems";
import ItemView from "../reducers/ItemView";
import PlaceOrder from "../reducers/PlaceOrder";
import Sync from "../reducers/Sync";
import General from "../reducers/General";

const allReducers = combineReducers({
  ItemAddToCart: ItemAddToCart,
  CategorySliderBar: CategorySliderBar,
  MenuItems: MenuItems,
  ItemView: ItemView,
  PlaceOrder: PlaceOrder,
  Sync: Sync,
  General: General,
});

const stores = applyMiddleware(thunk)(createStore);
// const stores = configureStore({
//     reducer:{
//         itemCart: itemCart
//     }
// })
// export default stores;
export default stores(allReducers);
