import React from "react";

export default function LoadingSpinner() {
  return (
    <div className="overlay">
      <div className="spinner-container center-screen">
        <div className="loading-spinner"></div>
      </div>
    </div>
  );
}
